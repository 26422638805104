<template>
  <div class="overflow-hidden">
    <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login" modal-class="modal-session-app" centered
      title="Expired !" @ok="gotologin()">
      <b-card-text>Session Expired</b-card-text>
    </b-modal>
    <b-card>
      <b-row class="align-items-center justify-content-between">
        <b-col class="col-lg-9 col-md-12">
          <div class="d-flex align-items-center">
            <h5 class="mb-0">Filter By Account Name :</h5>
            <b-form-group class="ml-3 flex-grow-1">
              <validation-provider #default="{ errors }" name="Filter By Account"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                <v-select class="filterSelect" id="h-account-name" v-model="accountName" :options="accountNames"
                  @change="filterImportsHistoryByAccount()" />
                <small class="text-danger account-error">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-col>
        <b-col class="col-lg-3 col-md-12 my-3 my-md-0 d-flex justify-content-center">
          <b-form-group class="mb-0">
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-success" to="/myprofile/apps/creation">
              Add an App <feather-icon icon="PlusIcon" size="12" />
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row class="align-items-center justify-content-between">
        <b-col cols="6" offset="6" class="mb-1">
          <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
            class="mb-0 align-items-center">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-modal id="modal-danger" ok-variant="danger" ok-title="Confirm" modal-class="modal-danger" centered
          title="Delete" @ok="deleteApp">
          <b-card-text> Are you sure you want to delete this app? </b-card-text>
        </b-modal>
        <b-col cols="12" class="px-0">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
            <template #cell(action)="data">
              <b-button-group>
                <!--
                <a
                  :href="data.item.playstoreUrl"
                  target="_blank"
                  class="btn btn-icon rounded-circle btn-flat-primary"
                  v-b-tooltip.hover
                  title="Visit"
                >
                  <feather-icon icon="LinkIcon" size="20" />
                </a>
                -->
                <b-button v-ripple.400="'rgba(56, 144, 226, 0.15)'" variant="flat-primary" class="btn-icon rounded-circle"
                  v-b-tooltip.hover title="Refresh" @click="refreshApp(data.item)">
                  <feather-icon icon="RefreshCcwIcon" size="20" />
                </b-button>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-info" class="btn-icon rounded-circle "
                  v-b-tooltip.hover title="Info" :to="'/myprofile/apps/' + data.item.id + ',db,0/info'">
                  <feather-icon icon="InfoIcon" size="20" />
                </b-button>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
                  v-b-tooltip.hover title="Delete" @click="setDeleteId(data.item.id)">
                  <feather-icon icon="Trash2Icon" size="20" />
                </b-button>
              </b-button-group>
            </template>
            <template #cell(appId)="data">
              <span class="text-break">
                {{ data.value }}
              </span>
            </template>
            <template #cell(icon)="data">
              <img :src="data.value" alt="" width="20" />
            </template>
            <template #cell(version)="data">
              <span class="badge bg-primary rounded-pill px-1 app-score overflow-hidden"
                style="max-width: 60px; text-overflow: ellipsis">{{ data.value }}</span>
            </template>
            <template #cell(rate)="data"> {{ data.value }} / 5 </template>
          </b-table>
        </b-col>
        <b-col cols="6">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-25" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
            class="my-0" />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BContainer,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BModal,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "@axios";
import store from "@/store";
import router from "@/router";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { deleteAccessTokenCookie } from "@/helper/helpers.js";
import { connectApi } from "@/helper/connectApi";
export default {
  components: {
    BContainer,
    BTable,
    BAvatar,
    VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      appToDelete: null,

      accountName: null,
      accountNames: [],

      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // url: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "icon",
          label: "",
        },
        {
          key: "title",
          label: "App Name",
          // thStyle: { width: "70%" },
        },
        {
          key: "rate",
          label: "Score",
        },
        {
          key: "installs",
          label: "Installs",
          sortable: true,
        },
        {
          key: "released",
          label: "Release date",
          sortable: true,
        },
        {
          key: "version",
          label: "Version",
        },
        {
          key: "account_name",
          label: "Account",
        },
        {
          key: "refreshed_at",
          label: "Refresh Date",
        },
        "Action",
      ],
      items: [],
      token: null,
      server: 1,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    accountName(value) {
      if (value) this.filterApplication(value.value);
      else this.showAllApps();
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.showAllApps();
    this.showAllAccounts();
  },
  created() { },
  methods: {
    filterApplication(accountId) {
      axios
        .post(`/get-apps-by-account`, {
          accountId,
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.packageNames = [];
            if (response.data.count === 0) {
              this.items = [];
              this.totalRows = 0;
            } else {
              this.items = response.data.apps;
              this.totalRows = this.items.length;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setDeleteId(id) {
      this.appToDelete = id;
      this.$root.$emit("bv::show::modal", "modal-danger");
    },
    deleteApp() {
      var id = this.appToDelete;
      axios
        .post("/destroy-app", {
          id: id,
        })
        .then(({ data }) => {
          if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            this.showAllApps();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch(console.error);
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    showAllApps() {
      this.$store
        .dispatch("appsManager/getAllApps")
        .then(({ data }) => {
          this.items = data.apps;
          this.totalRows = this.items.length;
        })
        .catch(console.error);
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refreshApp(item) {
      // axios
      //   .get(
      //     `https://scx.clicklab.app/api/apps/${item.appId}/?fullDetail=true`
      //   )
      const response = connectApi(`https://scx.clicklab.app/api/apps/${item.appId}/?fullDetail=true`);
      response
        .then((response) => {
          const { status } = response.data;
          if (status === "Unauthorized") {
            deleteAccessTokenCookie();
            this.$root.$emit("bv::show::modal", "modal-session-app");
            return;
          }
          const data = response.data;
          return data;
        })
        .then((data) => {
          axios
            .post("/refresh-app", {
              id: item.id,
              title: data.title,
              appId: data.appId,
              icon: data.icon,
              summary: data.summary,
              version: data.version,
              score: data.scoreText,
              released: data.released,
              installs: data.maxInstalls,
              ratings: data.ratings,
              genre: data.genre,
              developerEmail: data.developerEmail,
              developerWebsite: data.developerWebsite,
              contentRating: data.contentRating,
              descriptionHTML: data.descriptionHTML,
              androidVersionText: data.androidVersionText,
              screenshots: data.screenshots,
              priceText: data.priceText,
              developer: data.developer,
              maxInstalls: data.maxInstalls,
              last_update: data.last_update,
              updated: data.updated,
            })
            .then((response) => {
              if (response.data.status === "success") {
                this.showToast(
                  "success",
                  "SaveIcon",
                  "Done",
                  response.data.message
                );
                this.showAllApps();
                return;
                // this.$refs.resetButton.click();
              } else if (response.data.status === "failed") {
                this.showToast(
                  "danger",
                  "AlertTriangleIcon",
                  "Error",
                  "Please make sure the App ID IS A Valid ID!"
                );
              } else if (response.data.status === "warning") {
                this.showToast(
                  "warning",
                  "AlertOctagonIcon",
                  "Alert",
                  response.data.message
                );
              }
            })
            .catch((error) => {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                "Something went wrong!"
              );
            });
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Please make sure the App ID IS A Valid ID!"
          );
        });
    },
    async showAllAccounts() {
      await axios
        .post("/get-accounts", {
        })
        .then((response) => {
          this.accountNames = [];
          response.data.accounts.forEach((el) => {
            this.accountNames.push({
              label: el.account_name,
              value: el.id,
              accountId: el.account_id,
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    gotologin() {
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@media (max-width: 575.98px) {
  .col-md-12 {
    flex-basis: 100% !important;
  }

  .justify-content-center {
    justify-content: center !important;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .col-lg-9 {
    flex-basis: 90% !important;
  }
}
</style>